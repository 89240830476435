import { Cancel, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  paddingBottom: 3,
};

export default function InsuranceStatusModal({ open, setOpen, textContent }) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              display={"flex"}
              my={1}
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
            >
              <Cancel sx={{ fontSize: "6rem" }} color="error" />
              <Typography
                sx={{
                  mt: 2,
                  fontSize: { xs: "1rem", md: "1.5rem", textAlign: "center" },
                }}
              >
                Appointment Not Booked
              </Typography>
              {/* <IconButton>
                <Close />
              </IconButton> */}
            </Box>

            <Box>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                variant="body2"
                textAlign={"center"}
              >
                {textContent}
              </Typography>
            </Box>

            <Box display={"flex"} justifyContent={"center"} mt={3} gap={2}>
              <Button color="primary" onClick={handleClose}>Go Back</Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
