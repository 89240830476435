import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

const PaymentSuccess = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Box
      display={"flex"}
      my={1}
      flexDirection="column"
      justifyContent={"center"}
      alignItems="center"
    >
      <CheckCircleOutlineIcon sx={{ fontSize: "6rem" }} color="primary" />
      <Typography sx={{ mt: 2, fontSize: { xs: "1rem", md: "1.5rem" } }}>
      {t('TransactionComplete')}
      </Typography>
      <Typography fontSize="0.8rem" sx={{ mt: 0.5, mb: 2 }}>
      {t('ThankYouForChoosing')}
      </Typography>
      <Button variant="contained" sx={{ mt: 1 }} onClick={onClick}>
      {t('Okay')}
      </Button>
    </Box>
  );
};

export default PaymentSuccess;
