import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { viewDoctorStatus } from "../../app/apiManager/apiManager";
import { sessionStatus } from "../../app/apiManager/channelingStatusService";
import doctor_waiting from "../../assets/doctor_waiting.gif";
import { useTranslation } from 'react-i18next';

const StepTwo = ({
  setActiveStep,
  appointment,
  currentDoctorStatus,
  setCurrentDoctorStatus,
}) => {
  const { t } = useTranslation();
  function getDoctorStatus(doctorId, sessionId, status) {
    viewDoctorStatus(
      {
        doctorId,
        sessionId,
        status,
        country: "BD",
        channel: "Desktop",
        application: "VC",
      },
      (response) => {
        if (response.status === "success") {
          if (response.data.status === "ARRIVED") {
            setCurrentDoctorStatus({
              status: t('DoctorArrived'),
              color: "darkGreen",
            });
          }
        } else {
          setCurrentDoctorStatus({
            status: t('DoctorOnTheWay'),
            color: "blue",
          });
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  useEffect(() => {
    let timeout = "";

    if (currentDoctorStatus.status === "") {
      getDoctorStatus(appointment.DoctorId, appointment.SessionId, "ARRIVED");
    } else if (currentDoctorStatus.status !== "Doctor arrived") {
      timeout = setTimeout(() => {
        getDoctorStatus(appointment.DoctorId, appointment.SessionId, "ARRIVED");
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDoctorStatus]);

  useEffect(() => {
    let timeout = "";
    if (currentDoctorStatus.status === "Doctor arrived") {
      timeout = setTimeout(() => {
        setActiveStep(3);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDoctorStatus]);

  return (
    <Grid container sx={{ height: "100%", mt: 3 }}>
      <Grid item xs sm>
        <Typography
          fontSize="1.5rem"
          fontWeight="bold"
          sx={{
            ml: 1,
            ...(currentDoctorStatus.status === "Doctor arrived" && {
              color: "darkGreen",
            }),
          }}
        >
          {currentDoctorStatus.status === "Doctor arrived"
            ?  t('DoctorArrived!')
            :  t('WaitForDoctor')}
        </Typography>
        <Box display={"flex"} flexDirection="column" rowGap={1} mt={2} ml={1}>
          <Typography>{t('Doctor')} {appointment.DoctorName}</Typography>
          <Typography>
            {t('CurrentStatus')}{" "}
            <span
              style={{
                color: currentDoctorStatus.color,
              }}
            >
              {currentDoctorStatus.status}
            </span>{" "}
          </Typography>
          <Typography>
          Expected Time: {" "}
            {appointment?.SessionStartTime ? new Date(appointment?.SessionStartTime).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }) : ""}
          </Typography>
        </Box>
        <Box mt={2}>
          <Box
            sx={{
              backgroundColor: "#D6E5FA",
              width: "100%",
              p: 1,
              px: 2,
              borderRadius: 2,
            }}
          >
            <Typography
              sx={{
                mb: 1,
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
             {t('Messages')}
            </Typography>
            <Box
              sx={{
                overflowY: "auto",
                height: "8rem",
                scrollbarWidth: "thin",
              }}
            >
              {/* <MessageView message={"Hi I will be late today"} /> */}
              {/* no messages */}
              <Grid container mt={1}>
                <Grid
                  item
                  lg
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pr: 1,
                  }}
                >
                  <Divider sx={{ width: "100%", backgroundColor: "#949494" }} />
                </Grid>
                <Grid item lg={5}>
                  <Typography>{t('NoMessagesYet')}</Typography>
                </Grid>
                <Grid
                  item
                  lg
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pl: 1,
                  }}
                >
                  <Divider sx={{ width: "100%", backgroundColor: "#949494" }} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm
        display={"flex"}
        justifyContent="center"
        alignItems="center"
      >
        <img src={doctor_waiting} alt="" height={"70%"} />
      </Grid>
    </Grid>
  );
};

export default StepTwo;
