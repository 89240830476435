import { postAsyncV2 } from "./apiManager";

export const getConversionRatioByCountry = async (doctorId, branchId) => {
  const response = await fetch(
    `${process.env.REACT_APP_NEW_API_BASE_URL}conversion-ratios/get`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Country: "QA",
      }),
    }
  );
  return await response.json();
};
