export const getSession = async (sessionId) => {
  const response = await fetch(
    `${process.env.REACT_APP_MEDICA_URL}Session/Get/${sessionId}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        UserId: 2,
      },
    }
  );
  return await response.json();
};
