const concatPhoneNumberAndCountryCode = ({ countryCode, phoneNumber }) => {
  if (phoneNumber.charAt(0) === "0") {
    phoneNumber = phoneNumber.substring(1);
  }
  let phoneNumberWithCountryCode = countryCode;

  phoneNumberWithCountryCode += phoneNumber;

  return phoneNumberWithCountryCode;
};

export default concatPhoneNumberAndCountryCode;
