import { post, postAsync } from "./apiManager";

export const getNextAppointmentNumber = (
  body,
  onSuccess,
  onFailed,
  onComplete
) => {
  fetch(`${process.env.REACT_APP_MEDICA_URL}Appointment/GetNext`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      UserId: 2,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((response) => {
      onSuccess(response?.Data);
    })
    .catch((error) => {
      onFailed(error);
    })
    .finally(() => {
      if (onComplete) {
        onComplete();
      }
    });
};

export const getNextAppointmentNumberV2 = async ({ body }) => {
  const response = await fetch(
    `${process.env.REACT_APP_MEDICA_URL}Appointment/GetNext`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        UserId: 2,
      },
      body: JSON.stringify(body),
    }
  );
  return await response.json();
};

export const placeAppointment = (body, onSuccess, onFailed, onComplete) => {
  fetch(`${process.env.REACT_APP_MEDICA_URL}Appointment/Post`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      UserId: 2,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onFailed(error);
    })
    .finally(() => {
      if (onComplete) {
        onComplete();
      }
    });
};

export const placeAppointmentV2 = async ({ body }) => {
  const response = await fetch(
    `${process.env.REACT_APP_MEDICA_URL}Appointment/Post`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        UserId: 2,
      },
      body: JSON.stringify(body),
    }
  );

  return await response.json();
};

export const getAppointments = (
  PatientMobile,
  onSuccess = () => {},
  onFailed = () => {},
  onComplete = () => {}
) => {
  fetch(
    `${process.env.REACT_APP_MEDICA_URL}AppointmentPrescriptionRecord/GetAppointment`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        UserId: 2,
      },
      body: JSON.stringify({
        PatientMobile,
      }),
    }
  )
    .then((res) => res.json())
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onFailed(error);
    })
    .finally(() => {
      onComplete();
    });
};
