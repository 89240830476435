import React from 'react'
import './LoadingAnimation.css'

const LoadingPage = () => {
    //used for payment gateway dummy return url since mobile app payment does not have a return url.

  return (
    <div className="loading-overlay">
      <div className="loading-spinner">
        {/* Add your loading spinner or message here */}
      </div>
    </div>
  )
}

export default LoadingPage