class FileService {
  static async uploadFile({ file, userId }) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("userType", "Patient");
    formData.append("channel", "PatientApp");
    formData.append("deviceType", "Mobile");
    formData.append("userId", userId);
    formData.append("service", "ProfilePicture");
    const response = await fetch(
      "https://live.medica.lk/Uploader/v1/file/upload",
      {
        method: "POST",
        body: formData,
      }
    );
    return await response.json();
  }
}
export default FileService;
