export const getCountryByMobileNumber = ({ mobileNumber = "" }) => {
  let country = "";
  if (mobileNumber.substring(0, 2) === "94") {
    country = "SL";
  } else if (mobileNumber.substring(0, 3) === "880") {
    country = "BD";
  }else if (mobileNumber.substring(0, 2) === "60") {
    country = "MY";
  }
  return country;
};
