import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Overview from "./pages/Overview/Overview";
import SearchDoctor from "./pages/SearchDoctor/SearchDoctor";
import StatusPage from "./pages/StatusPage/StatusPage";
import Test from "./pages/Test";
import ThemeProvider from "./theme";
import SignIn from "./pages/SignIn/SignIn";
import Registration from "./pages/Registration/Registration";
import SelectPatient from "./pages/SelectPatient/SelectPatient";
import ViewAppointments from "./pages/Home/ViewAppointments";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import AccountDeleteRequest from "./pages/AccountDeleteRequest/AccountDeleteRequest";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getConversionRatioByCountry } from "./app/apiManager/conversionRatioService";
import { store } from "./app/store";
import { setConversionRation } from "./reducers/conversionRatioSlice";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

function App() {
  const getConversionRatios = async () => {
    try {
      const conversionRatioResponse = await getConversionRatioByCountry();

      console.log(conversionRatioResponse);
      if (
        Array.isArray(conversionRatioResponse?.data) &&
        conversionRatioResponse?.data.length > 0
      ) {
        store.dispatch(
          setConversionRation({
            conversionRatio: conversionRatioResponse?.data[0].ConversionRatio,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getConversionRatios();
  }, []);
  return (
    <div className="App">
      <ThemeProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route path="sign-in" element={<SignIn />} />
              <Route
                path="account-delete-request"
                element={<AccountDeleteRequest />}
              />
              <Route path="/register" element={<Registration />} />
              <Route path="/select-patient" element={<SelectPatient />} />
              <Route path="/view-appointments" element={<ViewAppointments />} />
              <Route path="/privacy-policy" element={<></>} />
            </Route>
            <Route path="/privacy-policy-updated" element={<PrivacyPolicy />} />
            <Route path="/app/overview" element={<Overview />} />
            <Route path="/search" element={<SearchDoctor />} />
            <Route path="/test" element={<Test />} />
            <Route path="/status" element={<StatusPage />} />
            <Route path="/loading" element={<LoadingPage />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
