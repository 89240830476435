export const PRODUCTION = true;

export const APPOINTMENT_STATUS = {
  PENDING: "pending",
  ONGOING: "Pending",
  COMPLETED: "Successful Consultation",
  CANCELLED_AS_NO_SHOW: "Cancellation as “No Show?",
  CANCELLED_WITH_RESCHEDULED: "Unsuccessful consultation with Rescheduling​",
  CANCELLED_WITH_REFUND: "Unsuccessful consultation with Refund",
};

export const PAYMENT_METHODS = {
  PAY_NOW: 2,
  CASH_PAYMENT: 5,
  PROMO: 6,
  PAY_LATER: 7,
  PAY_BY_INSURANCE: 8,
};

export const INSURANCE_APPROVAL_STATUS = {
  APPROVED: "approved",
  PENDING: "pending",
  REJECTED: "rejected",
  EXPIRED: "expired",
};
