import { Button, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import userImage from "../../assets/doctor.jpg";
import medicaLogo from "../../assets/medicaLogo.png";
import { useTranslation } from 'react-i18next';

const PatientProfileCard = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Box>
        <Paper elevation={2}>
          <Grid container sx={{ height: "5rem" }}>
            <Grid
              item
              xs
              display={"flex"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: "0.5rem",
                  padding: "0.5rem",
                }}
              >
                <img
                  src={userImage}
                  alt="medica logo"
                  width={"50px"}
                  style={{
                    borderRadius: "50%",
                  }}
                />
                <Box sx={{ marginLeft: "0.5rem" }}>
                  <h3> John Doe</h3>
                  <p>{t('FindDoctor')}</p>
                </Box>
              </Box>

              <Button
                variant="contained"
                sx={{ boxShadow: "none", display: { xs: "none", md: "unset" } }}
              >
               {t('RequestDoctor')}
              </Button>
            </Grid>
            <Grid
              item
              xs={2}
              md={1}
              display="flex"
              justifyContent="center"
              alignItems={"center"}
            >
              <img src={medicaLogo} alt="medica logo" />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
};

export default PatientProfileCard;
