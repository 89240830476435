import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import medica from "../../assets/medica.png";
import CustomModal from "../../components/CustomModal/CustomModal";
import { useTranslation } from 'react-i18next';

const ErrorText = styled(Typography)({
  color: "red",
  fontSize: "0.8rem",
});

const AccountDeleteRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const onClickRequest = async () => {
    try {
      let response = await fetch(
        "https://testapi.doctoronline.lk/medica/clinic/api/v2/account/delete-request",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            profileName:name,
            emailAddress:email,
            mobileNumber:phoneNumber,
          }),
        }
      );
      response = await response.json();
      console.log(response);

      if (response?.status === "success") {
        alert( t('SuccessfullySaved'));
        navigate("/");
      }
    } catch (error) {}
  };

  return (
    <CustomModal
      open={open}
      sx={{
        p: 3,
        py: 2,
        width: { xs: "90%", sm: 450 },
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontweight: "bold",
          }}
        >
         {t('AccountDeleteRequest')}
        </Typography>
      </Box>

      <Box>
        <Box display={"flex"} alignItems="end" mt={3} gap={1}>
          <Typography> {t('ProfileName')}</Typography>{" "}
        </Box>
        <Box mt={1}>
          <TextField
            placeholder={"Jhon Doe"}
            fullWidth
            size="small"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Box>
      </Box>

      <Box>
        <Box display={"flex"} alignItems="end" mt={1} gap={1}>
          <Typography> {t('EmailAddress')}</Typography>{" "}
        </Box>
        <Box mt={1}>
          <TextField
            placeholder={"jhondoe@gmail.com"}
            fullWidth
            size="small"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Box>
      </Box>

      <Box>
        <Box display={"flex"} alignItems="end" mt={1} gap={1}>
          <Typography> {t('RegisteredNumber')}</Typography>{" "}
        </Box>
        <Box mt={1}>
          <TextField
            placeholder={"07X XXX XXX"}
            fullWidth
            size="small"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
          width={"100%"}
          gap={0.5}
        >
          <img
            src={logo}
            alt="docnote logo"
            width={isDesktop ? "70px" : "50px"}
          />
          <Divider
            orientation="vertical"
            sx={{
              mt: 0.5,
              width: "2px",
              height: "15px",
              backgroundColor: "#ABABAB",
            }}
          />
          <img
            src={medica}
            alt="medica logo"
            width={isDesktop ? "90px" : "60px"}
          />
        </Box>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <Button variant="outlined" onClick={() => setOpen(false)}>
          {t('Cancel')}
          </Button>
          <Button variant="contained" onClick={onClickRequest}>
          {t('Request')}
          </Button>
        </Box>
      </Box>
    </CustomModal>
  );
};
export default AccountDeleteRequest;
