import { Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';

const TableHeading = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: "#f4f6f8",
        py: 1,
        borderRadius: 0.5,
        fontWeight: "bold",
      }}
    >
      <Grid item xs ml={2} display="flex" alignItems={"center"}>
      {t('PatientName')}
      </Grid>
      <Grid item xs={2} ml={2} display="flex" justifyContent={"center"}>
      {t('Gender')}
      </Grid>
      <Grid
        item
        ml={2}
        xs={2}
        mr={2}
        display="flex"
        justifyContent="center"
        alignItems={"center"}
      >
        {t('Age')}
      </Grid>
    </Grid>
  );
};

export default TableHeading;
