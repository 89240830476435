import React from "react";
import "./style.css"; // Import your CSS file for styling

import logo from "../../assets/logo.png";

const PrivacyPolicy = () => {
  return (
    <div>
      <header>
        <nav>
          <div className="logo">
            <img src={logo} alt="MEDICA Logo" />
          </div>
        </nav>
      </header>

      <div className="container">
        <h1>Privacy Notice - MEDICABangladesh</h1>
        <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
        <section id="introduction">
          <h2>1. Introduction</h2>
          <p>
            Welcome to the MEDICA Digital Health Services ("MEDICA," "we," "us,"
            or "our"). This Privacy Policy outlines our practices regarding the
            collection, use, and disclosure of personal information when you use
            our mobile application and related services.
          </p>
        </section>

        <section id="introduction" className="sections">
          <h2> 2. Information We Collect</h2>
        </section>

        <section id="introduction" className="sections">
          <h3> 2.1 User-Provided Information</h3>
          <ul style={{ "list-style-type": "disc", display: "unset" }}>
            <li>
              Account Information: When you register on the MEDICA app, we
              collect personal information such as your name, email address,
              date of birth, and contact details.
            </li>
            <li>
              Health Information: Users may voluntarily provide health-related
              information, including medical conditions, allergies,
              prescriptions, and other health records.
            </li>
            <li>
              Communication Data: Information exchanged between users,
              healthcare professionals, and caregivers within the app.
            </li>
          </ul>
        </section>

        <section id="introduction" className="sections">
          <h3> 2.2 Automatically Collected Information</h3>
          <ul style={{ "list-style-type": "disc", display: "unset" }}>
            <li>
              Device Information: We collect device-specific information,
              including device model, operating system, and unique identifiers.
            </li>
            <li>
              Usage Information: We gather data on how users interact with the
              app, including page views, clicks, and feature usage.
            </li>
            <li>
              Log Information: Server logs may capture details such as IP
              addresses, access times, and app errors.
            </li>
          </ul>
        </section>

        <section id="introduction" className="sections">
          <h3>2.3 Cookies and Similar Technologies</h3>
          <p>
            We may use cookies and similar technologies to enhance user
            experience and gather information about app usage. Users can manage
            cookie preferences through their device settings.
          </p>
        </section>

        <section id="introduction" className="sections">
          <h2>3 How We Use Your Information</h2>
        </section>

        <section id="introduction" className="sections">
          <h3>3.1 Personalized Services</h3>
          <p>
            We use the collected information to provide personalized healthcare
            services, including health record management, appointment
            scheduling, and communication features.
          </p>
        </section>

        <section id="introduction" className="sections">
          <h3>3.2 Communication</h3>
          <p>
            We may use contact information to send important notices, updates,
            and promotional materials. Users can opt out of promotional
            communications.
          </p>
        </section>

        <section id="introduction" className="sections">
          <h3>3.3 Analytics and Improvements</h3>
          <p>
            Aggregate and anonymized data may be used for analytics, research,
            and app improvements.
          </p>
        </section>

        <section id="introduction" className="sections">
          <h2>4. Data Sharing and Disclosures</h2>
        </section>

        <section id="introduction" className="sections">
          <h3>4.1 With Your Consent</h3>
          <p>
            We may share your information with third parties when you explicitly
            consent to such sharing.
          </p>
        </section>

        <section id="introduction" className="sections">
          <h3>4.2 Healthcare Providers and Caregivers</h3>
          <p>
            For the purpose of facilitating healthcare services, we may share
            relevant user information with authorized healthcare providers and
            caregivers.
          </p>
        </section>

        <section id="introduction" className="sections">
          <h3>4.3 Legal Compliance</h3>
          <p>
            We may disclose information when required by law, legal processes,
            or to protect our rights and interests.
          </p>
        </section>

        <section id="introduction" className="sections">
          <h2>5 Data Security</h2>
          <p>
            We employ industry-standard security measures to protect user data
            from unauthorized access, disclosure, alteration, and destruction.
          </p>
        </section>

        <section id="introduction" className="sections">
          <h2>6 User Rights</h2>
          <p>
            Users have the right to access, correct, delete, or request the
            portability of their personal information. For such requests, please
            contact us at [insert contact email].
          </p>
        </section>

        <section id="introduction" className="sections">
          <h2>7 Children's Privacy</h2>
          <p>
            The MEDICA app is not intended for users under the age of 13. We do
            not knowingly collect information from children under 13. If you
            believe a child has provided us with personal information, please
            contact us immediately.
          </p>
        </section>

        <section id="introduction" className="sections">
          <h2>8 Changes to this Privacy Policy</h2>
          <p>
            This Privacy Policy may be updated periodically to reflect changes
            in our practices. Users will be notified of material changes.
          </p>
        </section>

        <section id="introduction" className="sections">
          <h2>9 Contact Information</h2>
          <p>
            For questions, concerns, or requests regarding this Privacy Policy,
            please contact us at info@medica.lk.
          </p>
        </section>
        {/* Add other sections for different parts of the Privacy Policy */}
      </div>

      <footer>
        <div className="company-details">
          <p>Company Name: mobiOs Lanka Bangla Limited</p>
          <p>Email: info@medica.lk</p>
          {/* Add other company details here */}
          <p>
            Address: Level 9, Plot 11, Road 113A, Gulshan 2, Dhaka 1212,
            Bangladesh{" "}
          </p>
          {/* <p>Phone: [Insert Company Phone Number]</p> */}
          {/* <p>Registration Number: [Insert Company Registration Number]</p> */}
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
