import { getCountryByMobileNumber } from "../../utils/getCountryByMobileNumber";

const isOTPEnabled = true;

export const sendOTP = (phoneNumber, onSuccess, onFailed) => {
  const Country = getCountryByMobileNumber({ mobileNumber: phoneNumber });
  console.log(phoneNumber,Country);

  fetch(`${process.env.REACT_APP_NEW_API_BASE_URL}otp-v2`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      MobileNumber: isOTPEnabled ? phoneNumber : "0770543422",
      Country,
      InstituteId:process.env.REACT_APP_INSTITUTE_ID
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      onSuccess(response.data, response);
    })
    .catch((e) => {
      onFailed(e);
    });
};

export const sendOtpV2 = async (mobileNumber) => {
  const Country = getCountryByMobileNumber({ mobileNumber: mobileNumber });
  console.log(mobileNumber,Country);

  const response = await fetch(
    `${process.env.REACT_APP_NEW_API_BASE_URL}otp-v2`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        MobileNumber: isOTPEnabled ? mobileNumber : "0770543422",
        Country,
        InstituteId:process.env.REACT_APP_INSTITUTE_ID
      }),
    }
  );
  const responseBody = await response.json();
  return responseBody;
};

export const verifyOTP = (phoneNumber, otpValue, onSuccess, onFailed) => {
  fetch(`${process.env.REACT_APP_NEW_API_BASE_URL}verify-otp-v2`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      MobileNumber: phoneNumber,
      OTPValue: otpValue,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      onSuccess(response, response);
    })
    .catch((e) => {
      onFailed(e);
    });
};

export const verifyOtpV2 = async (mobileNumber, otpValue) => {
  const response = await fetch(
    `${process.env.REACT_APP_NEW_API_BASE_URL}verify-otp-v2`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        MobileNumber: mobileNumber,
        OTPValue: otpValue,
      }),
    }
  );
  const responseBody = await response.json();

  if (responseBody.Message === "Success") {
    return true;
  }
  return false;

  //  const data = await response.json();
  //  return data;
};
