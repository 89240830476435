import { createSlice } from "@reduxjs/toolkit";

export const conversionRatioSlice = createSlice({
  name: "loadingStatus",
  initialState: {
    conversionRatio: 1,
  },
  reducers: {
    setConversionRation: (state, action) => {
      state.conversionRatio = action.payload.conversionRatio;
    },
  },
});

export const { setConversionRation } =
  conversionRatioSlice.actions;

export default conversionRatioSlice.reducer;
