import {
  AccessTime,
  CalendarMonth,
  CheckCircleOutline,
  Duo,
  PeopleAlt,
  Person,
  ThumbDownOffAlt,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { APPOINTMENT_STATUS } from "../../const";
import palette from "../../theme/palette";
import { useTranslation } from 'react-i18next';

const AppointmentCard = ({
  appointmentId,
  doctorName,
  dateTime,
  channelingStatus,
}) => {
  const { t } = useTranslation();
  let icon = "";
  switch (channelingStatus) {
    case APPOINTMENT_STATUS.PENDING:
      icon = (
        <AccessTime
          sx={{
            color: palette.light.primary.main,
          }}
        />
      );
      break;
    case APPOINTMENT_STATUS.COMPLETED:
      icon = (
        <CheckCircleOutline
          sx={{
            color: palette.light.success.main,
          }}
        />
      );
      break;
    case APPOINTMENT_STATUS.ONGOING:
      icon = (
        <PeopleAlt
          sx={{
            color: palette.light.primary.main,
          }}
        />
      );
      break;
    default:
      icon = (
        <ThumbDownOffAlt
          sx={{
            color: palette.light.error.main,
          }}
        />
      );
      break;
  }
  return (
    <Box
      sx={{
        border: "1px solid #E5E5E5",
        borderRadius: "5px",
        padding: 1,
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.05)",
        "& ": {
          "&:hover": {
            boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.3)",
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            color: palette.light.primary.main,
            fontWeight: "bold",
          }}
        >
          #{appointmentId}
        </Typography>
        <Box
          title={channelingStatus}
          sx={{
            pr: 1,
          }}
        >
          {icon}
        </Box>
      </Box>

      <Typography mt={1}>{t('AppointmentWith')} {doctorName}</Typography>

      <Box
        sx={{
          display: "flex",
          gap: 1,
          position: "relative",
          "&": {
            color: "#606a73",
          },
        }}
      >
        <Box
          sx={{
            mt: 1,
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <CalendarMonth
            sx={{
              fontSize: "0.8rem",
            }}
          />
          <Typography fontSize="0.8rem">
            {new Date(dateTime).toLocaleDateString()}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <AccessTime
            sx={{
              fontSize: "0.8rem",
            }}
          />
          <Typography fontSize="0.8rem" color={palette.light.grey}>
            {new Date(dateTime).toLocaleTimeString()}
          </Typography>
        </Box>
        <Button
          variant="text"
          sx={{
            position: "absolute",
            right: 0,
            minWidth: "unset",
            p: 0,
            px: 1,
          }}
          onClick={() => {
            window.open(`/status?appointmentId=${appointmentId}`, "_blank");
          }}
        >
         {t('view')}
        </Button>
      </Box>
    </Box>
  );
};

export default AppointmentCard;
