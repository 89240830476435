import { CalendarToday } from "@mui/icons-material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import ScienceIcon from "@mui/icons-material/Science";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Person4Icon from "@mui/icons-material/Person4";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";



export const DOCTORS = [
  {

    title: "Travis Hernandez",
    specialization: "Oncologist",
    availableDate: "2022-02-06",
  },
  {
    title: "Christine Becker",
    specialization: "Pediatrician",
    availableDate: "2022-07-01",
  },
  {
    title: "Marie Gonzalez",
    specialization: "Plastic surgery",
    availableDate: "2022-12-02",
  },
  {
    title: "Tiffany Howard",
    specialization: "Surgent",
    availableDate: "2022-01-12",
  },
  {
    title: "Christopher Thompson",
    specialization: "Pediatrician",
    availableDate: "2022-03-05",
  },
  {
    title: "Victoria Bailey",
    specialization: "Surgent",
    availableDate: "2022-01-27",
  },
  {
    title: "Kaitlyn Dawson",
    specialization: "Oncology",
    availableDate: "2022-04-18",
  },
  {
    title: "Dana Floyd",
    specialization: "Surgent",
    availableDate: "2022-03-28",
  },
  {
    title: "Latoya Mckinney",
    specialization: "Pediatrician",
    availableDate: "2022-09-20",
  },
  {
    title: "Jesus Williams",
    specialization: "Psychiatrist",
    availableDate: "2022-04-16",
  },
  {
    title: "Timothy Brooks",
    specialization: "Surgent",
    availableDate: "2022-12-10",
  },
  {
    title: "Karen Clark",
    specialization: "Surgent",
    availableDate: "2022-12-28",
  },
  {
    title: "Jonathan Lowe",
    specialization: "Nurologist",
    availableDate: "2022-09-06",
  },
  {
    title: "William Dunn",
    specialization: "Oncologist",
    availableDate: "2022-06-11",
  },
  {
    title: "Michelle Steele",
    specialization: "Nurologist",
    availableDate: "2022-02-01",
  },
  {
    title: "Savannah Hoffman",
    specialization: "Surgent",
    availableDate: "2022-07-07",
  },
  {
    title: "Jamie Stewart",
    specialization: "Surgent",
    availableDate: "2022-12-28",
  },
  {
    title: "Benjamin Dunn",
    specialization: "Nurologist",
    availableDate: "2022-09-15",
  },
  {
    title: "James French",
    specialization: "Pediatrician",
    availableDate: "2022-03-26",
  },
  {
    title: "Heather George",
    specialization: "Surgent",
    availableDate: "2022-04-20",
  },
  {
    title: "Crystal Davis",
    specialization: "Surgent",
    availableDate: "2022-04-09",
  },
  {
    title: "George Kline",
    specialization: "Surgent",
    availableDate: "2022-10-14",
  },
  {
    title: "Krista Martinez",
    specialization: "Ophthalmology",
    availableDate: "2022-08-28",
  },
  {
    title: "Andrew Thompson",
    specialization: "Ophthalmologist",
    availableDate: "2022-08-24",
  },
  {
    title: "Eric Russell",
    specialization: "Cardiologist",
    availableDate: "2022-12-24",
  },
  {
    title: "Nancy Webb",
    specialization: "Ophthalmology",
    availableDate: "2022-10-07",
  },
  {
    title: "Edward Howard",
    specialization: "Cardiologist",
    availableDate: "2022-04-13",
  },
  {
    title: "Carlos Nelson",
    specialization: "Plastic surgery",
    availableDate: "2022-12-14",
  },
  {
    title: "Courtney Cardenas",
    specialization: "Ophthalmology",
    availableDate: "2022-10-01",
  },
  {
    title: "Keith Torres",
    specialization: "Oncologist",
    availableDate: "2022-06-09",
  },
  {
    title: "Jonathan Huynh",
    specialization: "Ophthalmology",
    availableDate: "2022-06-05",
  },
  {
    title: "Willie Hill",
    specialization: "Plastic surgery",
    availableDate: "2022-06-20",
  },
  {
    title: "Heather Peterson",
    specialization: "Nurologist",
    availableDate: "2022-09-24",
  },
  {
    title: "Zachary Henderson",
    specialization: "Surgent",
    availableDate: "2022-07-01",
  },
  {
    title: "Charles Hodges",
    specialization: "Psychiatrist",
    availableDate: "2022-05-01",
  },
  {
    title: "Gregory Mann",
    specialization: "Plastic surgery",
    availableDate: "2022-05-18",
  },
  {
    title: "Jenna Rosales",
    specialization: "Surgent",
    availableDate: "2022-01-22",
  },
  {
    title: "Albert Price",
    specialization: "Nurologist",
    availableDate: "2022-02-10",
  },
  {
    title: "Tina Gonzalez",
    specialization: "Oncology",
    availableDate: "2022-07-15",
  },
  {
    title: "Kyle Boone",
    specialization: "Ophthalmology",
    availableDate: "2022-06-21",
  },
  {
    title: "Kevin Richmond",
    specialization: "Oncology",
    availableDate: "2022-02-26",
  },
  {
    title: "Connie Smith",
    specialization: "Plastic surgery",
    availableDate: "2022-01-04",
  },
  {
    title: "Ashley King",
    specialization: "Psychiatrist",
    availableDate: "2022-05-25",
  },
  {
    title: "Patricia Steele",
    specialization: "Ophthalmology",
    availableDate: "2022-06-05",
  },
  {
    title: "Scott Osborne",
    specialization: "Cardiologist",
    availableDate: "2022-09-19",
  },
  {
    title: "Shane Chandler",
    specialization: "Pediatrician",
    availableDate: "2022-07-15",
  },
  {
    title: "Michael Rios",
    specialization: "Nurologist",
    availableDate: "2022-12-05",
  },
  {
    title: "Michael Simpson",
    specialization: "Pediatrician",
    availableDate: "2022-07-09",
  },
  {
    title: "Kevin Larson",
    specialization: "Psychiatrist",
    availableDate: "2022-03-13",
  },
  {
    title: "Joe Smith",
    specialization: "Psychiatrist",
    availableDate: "2022-05-04",
  },
  {
    title: "Ryan Kline",
    specialization: "Plastic surgery",
    availableDate: "2022-04-09",
  },
  {
    title: "James Hoover",
    specialization: "Surgent",
    availableDate: "2022-04-14",
  },
  {
    title: "Donna Walsh",
    specialization: "Oncology",
    availableDate: "2022-09-18",
  },
  {
    title: "Dave Jones",
    specialization: "Nurologist",
    availableDate: "2022-05-12",
  },
  {
    title: "Linda Dudley",
    specialization: "Ophthalmology",
    availableDate: "2022-04-04",
  },
  {
    title: "Justin Vega",
    specialization: "Plastic surgery",
    availableDate: "2022-01-06",
  },
  {
    title: "Jennifer Anderson",
    specialization: "Surgent",
    availableDate: "2022-07-05",
  },
  {
    title: "Karen Hensley",
    specialization: "Pediatrician",
    availableDate: "2022-12-27",
  },
  {
    title: "Thomas Foster",
    specialization: "Pediatrician",
    availableDate: "2022-01-17",
  },
  {
    title: "Kevin Cooper",
    specialization: "Ophthalmology",
    availableDate: "2022-02-06",
  },
  {
    title: "Amy Miles",
    specialization: "Oncology",
    availableDate: "2022-03-12",
  },
  {
    title: "Kimberly Davis",
    specialization: "Oncologist",
    availableDate: "2022-01-06",
  },
  {
    title: "Donald Middleton",
    specialization: "Cardiologist",
    availableDate: "2022-10-20",
  },
  {
    title: "Kimberly Dean",
    specialization: "Cardiologist",
    availableDate: "2022-12-21",
  },
  {
    title: "Edwin Benjamin",
    specialization: "Surgent",
    availableDate: "2022-02-22",
  },
  {
    title: "Perry Park",
    specialization: "Nurologist",
    availableDate: "2022-01-21",
  },
  {
    title: "Roberto Ramos",
    specialization: "Plastic surgery",
    availableDate: "2022-09-07",
  },
  {
    title: "Samuel Carr",
    specialization: "Ophthalmology",
    availableDate: "2022-07-20",
  },
  {
    title: "Ashley Miller",
    specialization: "Plastic surgery",
    availableDate: "2022-10-21",
  },
  {
    title: "Chelsea Maldonado",
    specialization: "Oncologist",
    availableDate: "2022-06-27",
  },
  {
    title: "Jeff Peterson",
    specialization: "Ophthalmologist",
    availableDate: "2022-01-04",
  },
  {
    title: "Julia Wyatt",
    specialization: "Oncology",
    availableDate: "2022-02-05",
  },
  {
    title: "Gregory Meyers",
    specialization: "Psychiatrist",
    availableDate: "2022-10-12",
  },
  {
    title: "Sharon Bell",
    specialization: "Psychiatrist",
    availableDate: "2022-09-27",
  },
  {
    title: "Shane Melton",
    specialization: "Cardiologist",
    availableDate: "2022-09-16",
  },
  {
    title: "Daniel Case",
    specialization: "Surgent",
    availableDate: "2022-09-01",
  },
  {
    title: "Shannon Mcneil",
    specialization: "Ophthalmologist",
    availableDate: "2022-02-07",
  },
  {
    title: "Christina Walter",
    specialization: "Ophthalmology",
    availableDate: "2022-10-06",
  },
  {
    title: "Eric Garrett",
    specialization: "Plastic surgery",
    availableDate: "2022-03-01",
  },
  {
    title: "Anthony Davis",
    specialization: "Plastic surgery",
    availableDate: "2022-06-26",
  },
  {
    title: "Ronald Davis",
    specialization: "Oncologist",
    availableDate: "2022-12-24",
  },
  {
    title: "James Martinez",
    specialization: "Cardiologist",
    availableDate: "2022-03-13",
  },
  {
    title: "Richard Carlson",
    specialization: "Ophthalmologist",
    availableDate: "2022-12-21",
  },
  {
    title: "Susan Russell",
    specialization: "Ophthalmologist",
    availableDate: "2022-11-16",
  },
  {
    title: "Janet May",
    specialization: "Plastic surgery",
    availableDate: "2022-03-20",
  },
  {
    title: "Tony Huang",
    specialization: "Ophthalmology",
    availableDate: "2022-06-03",
  },
  {
    title: "Stacey Adams",
    specialization: "Nurologist",
    availableDate: "2022-12-13",
  },
  {
    title: "Cynthia Reyes",
    specialization: "Pediatrician",
    availableDate: "2022-09-11",
  },
  {
    title: "Margaret Hanna",
    specialization: "Oncology",
    availableDate: "2022-12-09",
  },
  {
    title: "Sarah Blackwell",
    specialization: "Ophthalmologist",
    availableDate: "2022-09-05",
  },
  {
    title: "Paul Thompson",
    specialization: "Ophthalmologist",
    availableDate: "2022-07-26",
  },
  {
    title: "Ann Smith",
    specialization: "Ophthalmology",
    availableDate: "2022-01-15",
  },
  {
    title: "Scott Roth",
    specialization: "Plastic surgery",
    availableDate: "2022-02-19",
  },
  {
    title: "Keith Fowler",
    specialization: "Cardiologist",
    availableDate: "2022-01-27",
  },
  {
    title: "Gregory Scott",
    specialization: "Ophthalmology",
    availableDate: "2022-03-04",
  },
  {
    title: "Jennifer Taylor",
    specialization: "Pediatrician",
    availableDate: "2022-06-27",
  },
  {
    title: "Teresa Wright",
    specialization: "Ophthalmology",
    availableDate: "2022-03-17",
  },
  {
    title: "Ricardo Francis",
    specialization: "Pediatrician",
    availableDate: "2022-08-27",
  },
  {
    title: "Beth Ingram",
    specialization: "Nurologist",
    availableDate: "2022-07-27",
  },
];

export const SPECIALITIES = [
  { title: "Surgent" },
  { title: "Psychiatrist" },
  { title: "Oncology" },
  { title: "Pediatrician" },
  { title: "Ophthalmology" },
  { title: "Plastic surgery" },
  { title: "Ophthalmologist" },
  { title: "Cardiologist" },
  { title: "Oncologist" },
];

export const FAQ = [
  {
    question: {
      id: 'faq.question1',
    },
    answer: {
      id: 'faq.answer1',
    },
  },
  {
    question: {
      id: 'faq.question2',
    },
    answer: {
      id: 'faq.answer2',
    },
  },
  {
    question: {
      id: 'faq.question3',
    },
    answer: {
      id: 'faq.answer3',
    },
  },
  {
    question: {
      id: 'faq.question4',
    },
    answer: {
      id: 'faq.answer4',
    },
  },
  {
    question: {
      id: 'faq.question5',
    },
    answer: {
      id: 'faq.answer5',
    },
  },
  {
    question: {
      id: 'faq.question6',
    },
    answer: {
      id: 'faq.answer6',
    },
  },
  {
    question: {
      id: 'faq.question7',
    },
    answer: {
      id: 'faq.answer7',
    },
  },
  {
    question: {
      id: 'faq.question8',
    },
    answer: {
      id: 'faq.answer8',
    },
  },
];

export const TERMS_AND_CONDITIONS = [
  {
    title: {
      id: 'Terms.title1',
    },
    content: {
      id: 'Terms.content1',
    },
    pointCotent: [],
  },
  {
    title: {
      id: 'Terms.title2',
    },
    content: {
      id: 'Terms.content2',
    },
    pointCotent: [],
  },
  {
    title: {
      id: 'Terms.title3',
    },
    content: {
      id: 'Terms.content3',
    },
    pointCotent: [],
  },
  {
    title: {
      id: 'Terms.title4',
    },
    content: {
      id: 'Terms.content4',
    },
    pointCotent: [],
  },
  {
    title: {
      id: 'Terms.title5',
    },
    content: {
      id: 'Terms.content5',
    },
    pointCotent: [],
  },
  {
    title: {
      id: 'Terms.title6',
    },
    content: {
      id: 'Terms.content6',
    },
    pointCotent: [],
  },
  {
    title: {
      id: 'Terms.title7',
    },
    content: {
      id: 'Terms.content7',
    },
    pointCotent: [],
  },
  {
    title: {
      id: 'Terms.title8',
    },
    content: {
      id: 'Terms.content8',
    },
    pointCotent: [],
  },

  {
    title: {
      id: 'Terms.title9',
    },
    content: {
      id: 'Terms.content9',
    },
    pointCotent: [
      {
        title: {
          id: 'Terms.points.title',
        },
        points: [
          {
            id: 'Terms.points.point1',
          },
          {
            id: 'Terms.points.point2',
          },
          {
            id: 'Terms.points.point3',
          },
          {
            id: 'Terms.points.point4',
          },
        ],
      },
    ],
  },
  {
    title: {
      id: 'Terms.title10',
    },
    content: {
      id: 'Terms.content10',
    },
    pointCotent: [],
  },
  {
    title: {
      id: 'Terms.title11',
    },
    content: {
      id: 'Terms.content11',
    },
    pointCotent: [],
  },
  {
    title: {
      id: 'Terms.title12',
    },
    content: {
      id: 'Terms.content12',
    },
    pointCotent: [],
  },
  {
    title: {
      id: 'Terms.title13',
    },
    content: {
      id: 'Terms.content13',
    },
    pointCotent: [
      {
        title: {
          id: 'Terms.points.title1',
        },
        points: [
          {
            id: 'Terms.points.point5',
          },
          {
            id: 'Terms.points.point6',
          },
          {
            id: 'Terms.points.point7',
          },

        ],
      },
    ],
  },

  {
    title: {
      id: 'Terms.title14',
    },
    content: {
      id: 'Terms.content14',
    },
    pointCotent: [],
  },
  {
    title: {
      id: 'Terms.title15',
    },
    content: {
      id: 'Terms.content15',
    },
    pointCotent: [],
  },
];

export const WHAT_WE_OFFER = [
  {

    icon: (
      <EventAvailableIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: {
      id: 'WhatWeOffer.title1',
    },
    content: {
      id: 'WhatWeOffer.content1',
    },
  },
  {
    icon: (
      <SafetyDividerIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: {
      id: 'WhatWeOffer.title2',
    },
    content: {
      id: 'WhatWeOffer.content2',
    },
  },
  {
    icon: (
      <ScienceIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: {
      id: 'WhatWeOffer.title3',
    },
    content: {
      id: 'WhatWeOffer.content3',
    },
  },
  {
    icon: (
      <LocalShippingIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: {
      id: 'WhatWeOffer.title4',
    },
    content: {
      id: 'WhatWeOffer.content4',
    },
  },
  {
    icon: (
      <HomeWorkIcon
        sx={{
          mt: 0.5,
          fontSize: 20,
        }}
      />
    ),
    title: {
      id: 'WhatWeOffer.title5',
    },
    content: {
      id: 'WhatWeOffer.content5',
    },
  },
];

export const ONGOING_APPOINTMENTS = [
  {
    id: 1,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
  {
    id: 2,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
  {
    id: 3,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
  {
    id: 4,
    dateTime: "2021-08-01T10:00:00.000Z",
    status: "pending",
    type: "video",
    doctor: "Dr. Nimal Perera",
  },
];
