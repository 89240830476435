import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import appointmentInfo from "../reducers/appointmentInfoSlice";
import appointmentStatus from "../reducers/appointmentStatusSlice";
import placeAppointment from "../reducers/placeAppointmentSlice";
import gasAuth from "../reducers/gasAuthSlice";
import singIn from "../reducers/signInSlice";
import conversionRatioReducer from "../reducers/conversionRatioSlice";

function loadingReducer(state = {}, action) {
  if (action.type === "SET_LOADING_FOR") {
    state[action.payload.loadingAction] = 1;
  } else if (action.type === "CLEAR_LOADING_FOR") {
    state[action.payload.loadingAction] = undefined;
  }
  return state;
}

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["loadingStatus", "gasAuth", "conversionRatioReducer"],
};
const rootReducer = combineReducers({
  appointmentInfo: appointmentInfo,
  appointmentStatus: appointmentStatus,
  loadingStatus: loadingReducer,
  placeAppointment: placeAppointment,
  gasAuth: gasAuth,
  signIn: singIn,
  conversionRatio: conversionRatioReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
