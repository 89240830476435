class InsuranceService {
  static async getInsuranceByPatientId({ patientId }) {
    const response = await fetch(
      `${process.env.REACT_APP_NEW_API_BASE_URL}insurance/check-insurance-availability`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          PatientId: patientId,
        }),
      }
    );
    return await response.json();
  }

  static async sendInsuranceDetailsForApproval({
    InsuranceNumber,
    CompanyName,
    ExpiryDate,
    InstituteId,
    FullNameOfInsured,
    InsuranceCardFrontImage,
    InsuranceCardBacktImage,
    PatientId,
  }) {
    const response = await fetch(
      `${process.env.REACT_APP_NEW_API_BASE_URL}insurance/add-insurance-for-approval`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          InsuranceNumber,
          CompanyName,
          ExpiryDate,
          InstituteId,
          FullNameOfInsured,
          InsuranceCardFrontImage,
          InsuranceCardBacktImage,
          PatientId,
          UserId: null,
        }),
      }
    );
    return await response.json();
  }
}
export default InsuranceService;
