import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IconButton, TextField } from "@mui/material";
import { Cancel, Close, Done } from "@mui/icons-material";
import { useRef } from "react";
import { useState } from "react";
import FileService from "../app/apiManager/fileService";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import InsuranceService from "../app/apiManager/insuranceService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  paddingBottom: 3,
};

export default function AddInsuranceInfoModal({ open, setOpen }) {
  const frontFileRef = useRef();
  const backFileRef = useRef();
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [frontImageForUpload, setFrontImageForUpload] = useState(null);
  const [backImageForUpload, setBackImageForUpload] = useState(null);
  const [fileSelectionError, setFileSelectionError] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [currentAction, setCurrentAction] = useState("add-insurance");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const patientId = useSelector((state) => state.appointmentInfo.patientId);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFrontImageForUpload(null);
    setBackImageForUpload(null);
    setFrontImage(null);
    setBackImage(null);
    setFileSelectionError(null);

  };

  const handleFrontImageChange = (e) => {
    const file = e.target.files[0];
    setFrontImageForUpload(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFrontImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  React.useEffect(() => {
    if (!open) {
      setCurrentAction("add-insurance");
    }
  }, [open]);

  const handleBackImageChange = (e) => {
    const file = e.target.files[0];
    setBackImageForUpload(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setBackImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const sendDetailsForApproval = async (data) => {
    try {
      if (!frontImageForUpload || !backImageForUpload) {
        setFileSelectionError("Please select both images");
        return;
      }
      setShowLoading(true);
      console.log(data);
      const frontFileUploadResponse = await FileService.uploadFile({
        file: frontImageForUpload,
        userId: patientId,
      });

      const backFileUploadResponse = await FileService.uploadFile({
        file: backImageForUpload,
        userId: patientId,
      });

      console.log(frontFileUploadResponse);
      console.log(backFileUploadResponse);

      const frontFileName = frontFileUploadResponse?.file?.fileName;
      const backFileName = backFileUploadResponse?.file?.fileName;

      if (!frontFileName || !backFileName) {
        throw new Error("File Upload Failed");
      }

      const insuranceApprovalResponse =
        await InsuranceService.sendInsuranceDetailsForApproval({
          InsuranceNumber: data.insuranceNumber,
          CompanyName: data.companyName,
          ExpiryDate: data.expiryDate,
          InstituteId: process.env.REACT_APP_INSTITUTE_ID,
          FullNameOfInsured: data.nameOfInsured,
          InsuranceCardFrontImage: frontFileName,
          InsuranceCardBacktImage: backFileName,
          PatientId: patientId,
        });

      console.log(insuranceApprovalResponse);
      setCurrentAction("added-for-approval");
    } catch (error) {
      console.log(error);
      setCurrentAction("error-occured");
    } finally {
      setShowLoading(false);
    }
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {currentAction === "add-insurance" && (
              <>
                <input
                  type="file"
                  ref={frontFileRef}
                  hidden
                  onChange={handleFrontImageChange}
                />
                <input
                  type="file"
                  ref={backFileRef}
                  hidden
                  onChange={handleBackImageChange}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Pay by Insurance
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Box>
                <Box>
                  <Typography sx={{ mt: 1 }}>Insurance Number</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                    {...register("insuranceNumber", { required: true })}
                    error={errors.insuranceNumber ? true : false}
                    helperText={
                      errors.insuranceNumber ? "This field is required" : ""
                    }
                  />
                </Box>

                <Box>
                  <Typography sx={{ mt: 1 }}>Company Name</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                    {...register("companyName", { required: true })}
                    error={errors.companyName ? true : false}
                    helperText={
                      errors.companyName ? "This field is required" : ""
                    }
                  />
                </Box>
                <Box>
                  <Typography sx={{ mt: 1 }}>Expiry Date</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                    type="date"
                    {...register("expiryDate", {
                      required: true,
                      valueAsDate: true,
                    })}
                    error={errors.expiryDate ? true : false}
                    helperText={
                      errors.expiryDate ? "This field is required" : ""
                    }
                  />
                </Box>

                <Box>
                  <Typography sx={{ mt: 1 }}>
                    Full name of the Insured
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{
                      mt: 1,
                    }}
                    {...register("nameOfInsured", { required: true })}
                    error={errors.nameOfInsured ? true : false}
                    helperText={
                      errors.nameOfInsured ? "This field is required" : ""
                    }
                  />
                </Box>

                <Box>
                  <Typography sx={{ mt: 1 }}>
                    Upload Insurance Card (Both Sides) <br />
                    {fileSelectionError && (
                      <span style={{ color: "red" }}>
                        {" "}
                        Please select images for both sides
                      </span>
                    )}
                  </Typography>
                  <Box display={"flex"} mt={1} gap={1}>
                    <Box
                      sx={{
                        width: "60px",
                        height: "60px",
                        border: "1px dashed black",
                        borderRadius: 0.5,
                        "&:hover": {
                          border: "1px solid black",
                          cursor: "pointer",
                        },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        ...(frontImage && {
                          backgroundImage: `url(${frontImage})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }),
                      }}
                      onClick={() => {
                        frontFileRef.current.click();
                      }}
                    >
                      {frontImage ? "" : "Front Image"}
                    </Box>
                    <Box
                      sx={{
                        width: "60px",
                        height: "60px",
                        border: "1px dashed black",
                        borderRadius: 0.5,
                        "&:hover": {
                          border: "1px solid black",
                          cursor: "pointer",
                        },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        ...(backImage && {
                          backgroundImage: `url(${backImage})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }),
                      }}
                      onClick={() => {
                        backFileRef.current.click();
                      }}
                    >
                      {backImage ? "" : "Back Image"}
                    </Box>
                  </Box>
                </Box>

                <Box display={"flex"} justifyContent={"end"} mt={3} gap={2}>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(sendDetailsForApproval)}
                    variant="contained"
                    disabled={showLoading}
                  >
                    Confirm
                  </Button>
                </Box>
              </>
            )}

            {currentAction === "added-for-approval" && (
              <>
                <Box
                  display={"flex"}
                  my={1}
                  flexDirection="column"
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <Done sx={{ fontSize: "6rem" }} color="success" />
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: {
                        xs: "1rem",
                        md: "1.5rem",
                        textAlign: "center",
                      },
                    }}
                  >
                    Wait for approval
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    variant="body2"
                    textAlign={"center"}
                  >
                    Insurance information has been added for approval.
                  </Typography>
                </Box>

                <Box display={"flex"} justifyContent={"center"} mt={3} gap={2}>
                  <Button color="primary" onClick={handleClose}>
                    Go Back
                  </Button>
                </Box>
              </>
            )}
            {currentAction === "error-occured" && (
              <>
                <Box
                  display={"flex"}
                  my={1}
                  flexDirection="column"
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <Cancel sx={{ fontSize: "6rem" }} color="error" />
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: {
                        xs: "1rem",
                        md: "1.5rem",
                        textAlign: "center",
                      },
                    }}
                  >
                    Details not submitted
                  </Typography>
                  {/* <IconButton>
                <Close />
              </IconButton> */}
                </Box>

                <Box>
                  <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2 }}
                    variant="body2"
                    textAlign={"center"}
                  >
                    An error occured while adding insurance information. <br />
                    Please make sure to upload .jpg or .png image.
                  </Typography>
                </Box>

                <Box display={"flex"} justifyContent={"center"} mt={3} gap={2}>
                  <Button color="primary" onClick={handleClose}>
                    Go Back
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
