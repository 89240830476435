import {
  Box,
  Button,
  FormControl,
  Grid,
  NativeSelect,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { useState } from "react";
import palette from "../../theme/palette";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  AddHomeWorkOutlined,
  MedicalServicesOutlined,
  TtyOutlined,
} from "@mui/icons-material";
import allDoctors from "../../assets/all-doctors.png";
import availableNow from "../../assets/available-now.png";
import allSpecialist from "../../assets/all-specialities.png";
import { useTranslation } from 'react-i18next';


const ActionButton = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100px",
  width: "100px",
  borderRadius: "5px",
  paddingBottom: "5px",
  border: "1px solid #BFBFBF",
  "&:hover": {
    boxShadow: "0px 0px 5px 3px #BFBFBF",
    cursor: "pointer",
  },
});

const StyledTextField = styled(TextField)({
  ".MuiInput-underline": {
    marginTop: 3,
  },
});
const StepOne = ({ setDoctorFilterObj, specialities }) => {
  const { t } = useTranslation();
  const [doctorNameKeyword, setDoctorNameKeyword] = useState("");
  const [selectedSpecialization, setSelectedSpecialization] = useState("");
  const [date, setDate] = useState(new Date());

  const onClickAllSpecialist = () => {
    setDoctorFilterObj({
      doctorName: "",
      specialization: "",
      date: "",
    });
  };

  const onClickAllDoctors = () => {
    setDoctorFilterObj({
      doctorName: "",
      specialization: "",
      date: "",
    });
  };

  const onClickNowAvailable = () => {
    setDoctorFilterObj({
      doctorName: "",
      specialization: "",
      date: new Date(),
      strictlyTodayCheck: new Date(),
    });
  };

  return (
    <Grid item xs={12} sm lg mt={2.3}>
      <Box
        sx={{
          borderRadius: 3,
          border: "1px solid #BFBFBF",
          px: 2,
          pb: 3,
          pt: 1,
          "&:hover": {
            boxShadow: "0px 0px 2px 1px #BFBFBF",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            mb: 1.5,
          }}
        >
         {t('SearchDoctor')}
        </Typography>
        <Box>
          <TextField
            label= {t('dname')}
            fullWidth
            size="small"
            value={doctorNameKeyword}
            onChange={(e) => {
              setDoctorNameKeyword(e.target.value);
            }}
          />
        </Box>

        <Box mt={2}>
          <FormControl
            fullWidth
            sx={{
              "& .MuiInput-underline:before": {
                borderBottom: "none",
              },
              mb: 0.5,
              border: `1px solid transparent`,
            }}
          >
            <NativeSelect
              variant="outlined"
              sx={{
                "& select": {
                  backgroundColor: "transparent",
                  px: 2,
                },
                "& :focus": {
                  backgroundColor: "transparent",
                },
                "& :before": {
                  borderBottom: "none",
                },

                py: "4px",
                border: `1px solid ${palette.light.grey[400]}`,
                borderRadius: "5px",
              }}
              value={selectedSpecialization}
              onChange={(e) => {
                setSelectedSpecialization(e.target.value);
              }}
            >
              <option value= {t('special')}>{t('special')}</option>
              {[...new Set(specialities)].map((speciality, index) => {
                if (speciality === null) return null;
                return (
                  <option value={speciality} key={index}>
                    {speciality}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </Box>

        <Box mt={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              className="date-picker"
              sx={{ flexGrow: 1, mr: 2, width: { xs: "100%" } }}
              renderInput={(params) => (
                <StyledTextField
                  label= {t('select2')}
                  fullWidth
                  size="small"
                  sx={{
                    mt: 0,
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            gap: 1,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setDate(null);
              setDoctorNameKeyword('')
              setSelectedSpecialization('');
              setDoctorFilterObj({
                doctorName: '',
                specialization: '',
                date: '',
              });
            }}
          >
            {t('clear')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setDoctorFilterObj({
                doctorName: doctorNameKeyword,
                specialization: selectedSpecialization,
                date: date,
              });
            }}
            sx={{
              boxShadow: "none",
            }}
          >
            {t('searchForm')}
          </Button>
        </Box>
      </Box>
      <Box mt={2} display={"flex"} justifyContent={"space-between"}>
        <ActionButton onClick={onClickNowAvailable}>
          <Box flexGrow={1} display={"flex"} alignItems={"center"}>
            <img src={availableNow} alt="available now" width={"50px"} />
          </Box>
          <Typography
            fontSize={"0.75rem"}
            textAlign={"center"}
            fontWeight={"bold"}
          >
            {t('available')}
          </Typography>
        </ActionButton>
        <ActionButton onClick={onClickAllSpecialist}>
          <Box flexGrow={1} display={"flex"} alignItems={"center"}>
            <img src={allSpecialist} alt="all specialities" width={"50px"} />
          </Box>
          <Typography
            fontSize={"0.8rem"}
            textAlign={"center"}
            fontWeight={"bold"}
          >
            {t('AllSpecialist')}
          </Typography>
        </ActionButton>
        <ActionButton onClick={onClickAllDoctors}>
          <Box flexGrow={1} display={"flex"} alignItems={"center"}>
            <img src={allDoctors} alt="all doctors" width={"50px"} />
          </Box>
          <Typography
            fontSize={"0.8rem"}
            textAlign={"center"}
            fontWeight={"bold"}
          >
            {t('all')}
          </Typography>
        </ActionButton>
      </Box>
    </Grid>
  );
};

export default StepOne;
