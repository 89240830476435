import { Box, Chip, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import getOriginalDateTime from "../../utils/formatAMPM";
import { useTranslation } from 'react-i18next';

const Subtitle = ({ title, sessionId, appointmentDateTime }) => {
  const { t } = useTranslation();
  const date = new Date(appointmentDateTime);

  return (
    <div>
      <Typography
        fontWeight={"bold"}
        fontSize={"1.3rem"}
        sx={{ mt: { xs: 2, sm: 0 }, mb: { xs: 2, sm: 0 } }}
      >
        {t('AppointmentInfo')}
      </Typography>
      <Box mt={2}>
        <Grid container>
          <Grid item xs>
            <Typography fontWeight={"medium"}> {t('RefNo')} </Typography>
            <Typography>{sessionId} </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontWeight={"bold"}>{t('AppointmentDate')} </Typography>
            <Typography>
              {date
                ? getOriginalDateTime(appointmentDateTime).toLocaleDateString()
                : ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid item fontWeight={"bold"} xs>
            <Typography fontWeight={"bold"}>{t('AppointmentTime')}</Typography>
            <Typography>
              {date
                ? format(getOriginalDateTime(appointmentDateTime), "p")
                : ""}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography fontWeight={"bold"} fontSize={"1.3rem"} mt={1}>
        {title}
      </Typography>
    </div>
  );
};

export default Subtitle;
