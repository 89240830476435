import { Box, Chip, Grid, Typography } from "@mui/material";
import React from "react";
import ColouredLine from "../ColouredLine/ColouredLine";
import FlexBox from "../FlexBox/FlexBox";
import { useTranslation } from 'react-i18next';

const HeaderItem = ({
  hasNext = true,
  xs,
  stepNo,
  title,
  state = "COMPLETED",
  setActiveStep,
  icon,
}) => {
  const { t } = useTranslation();
  let styles = {};
  switch (state) {
    case "On - Going":
      styles = {
        color: "blue",
        backgroundColor: "#F0E8FF",
      };
      break;
    case "COMPLETED":
      styles = {
        color: "#2F9461",
        backgroundColor: "#C8F9E0",
      };
      break;
    case "PENDING":
      styles = {
        color: "#585858",
        backgroundColor: "#EEEEEE",
      };
      break;
    default:
  }
  return (
    <Grid item xs={true} {...(xs && xs)}>
      <Grid container mb={0.5}>
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <div
            onClick={() => {
              if (stepNo === "01") {
                setActiveStep(Number(stepNo));
              }
            }}
          >
            <FlexBox
              sx={{
                width: "35px",
                height: "35px",
                m: 1,
                mx: 0,
                ml: 0.5,
                borderRadius: "50%",

                color: "white",
                ...styles,
              }}
            >
              {icon}
            </FlexBox>
          </div>
        </Grid>
        {hasNext && (
          <Grid item xs>
            <FlexBox sx={{ height: "100%", mx: 2 }}>
              <ColouredLine
                width={"100%"}
                height="4px"
                sx={{
                  ...styles,
                }}
              />
            </FlexBox>
          </Grid>
        )}
      </Grid>
      <Box display={"flex"} flexDirection="column" rowGap={0.7}>
        <Typography
          fontSize={"0.6rem"}
          sx={{
            ...styles,
            backgroundColor: "unset",
            textOverflow: "unset",
          }}
          noWrap
        >
          {t('Step')}   {stepNo}
        </Typography>
        {}
        <>
          <Typography
            noWrap
            fontWeight={"bold"}
            fontSize="0.8rem"
            sx={{
              lineHeight: "0.7rem",
              ...(state === "On - Going"
                ? {
                    color: "blue",
                  }
                : {
                    display: { xs: "none", sm: "unset" },
                  }),
            }}
          >
            {title}
          </Typography>
          <Box>
            <Chip
              label={state}
              size="small"
              sx={{
                fontSize: "0.6rem",
                fontWeight: "bold",
                py: 0.3,
                ...styles,
                ...(state === "On - Going"
                  ? null
                  : {
                      display: { xs: "none", sm: "unset" },
                    }),
              }}
            />
          </Box>
        </>
      </Box>
    </Grid>
  );
};

export default HeaderItem;
